import VerEx from 'verbal-expressions'

const findKeyInObject = VerEx()
  .find('"')
  .somethingBut(',')
  .then('":')
  .toRegExp()

/**
 * Change keys of API response from snake_case to camelCase
 * @param text API response as text in snake_case
 * @returns API response as text in camelCase
 */
export const changeSnakeToCamelCase = (text: string): string => {
  const findSnake = VerEx().find('_').then(/./).toRegExp()

  return text.replaceAll(findKeyInObject, (keyText) => {
    return keyText.replaceAll(findSnake, (snakeText) => {
      return snakeText.substring(1).toUpperCase()
    })
  })
}

/**
 * Change keys for API request from camelCase to snake_case
 * @param text API request as text in camelCase
 * @returns API request as text in snake_case
 */
export const changeCamelCaseToSnake = (text: string): string => {
  const findUppercaseLetter = VerEx().find(/[A-Z]/).toRegExp()

  return text.replaceAll(findKeyInObject, (keyText) => {
    return keyText.replaceAll(findUppercaseLetter, (uppercaseLetter) => {
      return '_' + uppercaseLetter.toLocaleLowerCase()
    })
  })
}
