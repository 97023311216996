import { z } from 'zod'
import { Language } from '@/api/useLanguages'

export enum RoleName {
  Admin = 'admin',
  Editor = 'editor',
}

export const userSchema = z.strictObject({
  id: z.number(),
  companyId: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  jobTitle: z.string().nullable(),
  roleName: z.nativeEnum(RoleName),
  language: z.nativeEnum(Language),
  locationId: z.number().nullable(),
  emailNotifications: z.boolean(),
  useLightTheme: z.boolean(),
  insightsColumns: z.array(z.string()).nullable(),
  isDeleted: z.boolean(),
})

export type User = z.infer<typeof userSchema>

export interface UserFilterParams {
  includeDeleted?: boolean
}
